<apiz-grid-headless
  #grid
  class="apiz-grid-headless"
  (cellClicked)="onCellClicked($event)"
  (dataStateChanged)="onDataStateChanged($event)"
  (gridReady)="onGridReady($event)"
  (allSelected)="onRowSelected($event)"
  (pageChanged)="onPageChange()"
  (rowSelected)="onRowSelected($event)"
  [cacheRowCount]="cacheBlockSize"
  [maxCacheRowCount]="cacheBlockSize"
  [columnDefs]="columnsTableHeader"
  [columnFitContent]="true"
  [components]="data.frameworkComponents"
  [getContextMenuItems]="getContextMenuItems"
  [mergedPanels]="!isTabTopLevel || isOneColumn || isMobile"
  [border]="!isTabTopLevel"
  [pagination]="pagination"
  [paginationPageCountOption]="isTabTopLevel ? 'AUTO' : paginationPageSize ? paginationPageSize : 10"
  [paginationPageCountOptions]="paginationOption"
  [rowSelection]="rowSelection"
  [rowSelectionOnCellClicked]="!suppressRowClickSelection"
  [rowMultiSelectWithClick]="rowMultiSelectWithClick" />
