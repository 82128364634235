import { EventEmitter, Injectable } from '@angular/core';
import { URLParams } from '@web-desktop/models/url-params';

@Injectable({
  providedIn: 'root',
})
export class UrlParamsService {
  #urlParams: URLParams;

  handleURLEmt = new EventEmitter<void>();
  urlParamsChanged = new EventEmitter<URLParams>();

  setUrlParams(urlParams: URLParams) {
    this.#urlParams = urlParams;
    this.urlParamsChanged.emit(this.#urlParams);
  }

  getUrlParams(): URLParams {
    return this.#urlParams;
  }

  updateUrlParams(urlParams: Partial<URLParams>) {
    this.#urlParams = { ...this.#urlParams, ...urlParams };
    this.urlParamsChanged.emit(this.#urlParams);
  }
}
