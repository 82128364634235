import { Directive, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CompiereDataGridRequestJSON } from '@compiere-ws/models/compiere-data-json';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { IupicsWidget } from '@iupics-manager/models/iupics-widget';
import { SubscriptionsUtils } from '@iupics-util/tools/subscriptions.util';
import { WorkspaceService } from '@web-desktop/components/workspace/components/workspace-ui/workspace.service';
import { UrlParamsService } from '@web-desktop/controllers/url-params.service';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { Subscription } from 'rxjs';

@Directive()
export abstract class AbstractWidgetComponent implements OnInit, OnDestroy {
  @Input() isPreview: boolean = false;
  @Input() resizeWidgetEmitter: EventEmitter<any> = new EventEmitter();
  protected _widget: IupicsWidget;

  @Input()
  set widget(value) {
    // l'utilisation du setter permet de réagir à l'update du widget.
    this._widget = value;
    this.updateWidget();
  }
  get widget() {
    return this._widget;
  }
  subscriptions: Subscription[] = [];
  tableName: string;
  @Output() openTabEmitter = new EventEmitter<any>();
  @Output() widgetChanged = new EventEmitter<any>();

  readonly #workspaceService = inject(WorkspaceService);
  readonly #urlParamsService = inject(UrlParamsService);

  constructor(protected uiCreator: UICreatorService) {}

  ngOnInit(): void {}

  getDataGridRequest(): CompiereDataGridRequestJSON {
    if (this.widget.gridRequest) {
      return JSON.parse(this.widget.gridRequest);
    } else return null;
  }

  openTab(event: Event, item: { id: string; displayedName: string }) {
    if (event?.stopPropagation) {
      event.stopPropagation();
    }
    if (!this.isPreview) {
      const id = item?.id;
      let record_id = null;

      if (id) {
        record_id = id.split(',')[1];
        this.zoomAcross(record_id, this.tableName, this.widget.windowId);
      } else if (this.widget.windowId) {
        this.#urlParamsService.updateUrlParams({ dataGridRequest: this.getDataGridRequest() });
        this.#workspaceService.openTargetSearchEmt.emit({
          zoomInfo: null,
          cat: { id: this.widget.windowId },
          source: {
            id: null,
          },
        });
      }
    }
  }

  /* Zoom Across */
  zoomAcross(record_id: any, tableName: string, windowId: number, openNewRecord: boolean = false) {
    this.subscriptions.push(
      this.uiCreator.zoomAcross(tableName, tableName + '_ID', record_id ?? null, true, windowId).subscribe({
        next: (dataWs) => {
          if (dataWs && dataWs.length > 0) {
            let zoomInfo = {
              windowId: dataWs[0].Window_ID,
              dataUUID: dataWs[dataWs.length - 1]['Record_ID'],
              record_id: record_id ?? null,
              children: dataWs.length > 1 ? dataWs.splice(0, dataWs.length - 1) : null,
              isInCurrentWindow: true,
            };

            const source = {
              id: null,
              menuType: IupicsMenuType.WINDOW,
            };

            if (record_id) {
              source.id = dataWs[0]?.Record_ID ?? null;
            }

            if (openNewRecord) {
              source.id = 'newRecord';
            }

            this.#urlParamsService.getUrlParams().dataGridRequest = this.getDataGridRequest() ?? null;
            this.#workspaceService.openTargetSearchEmt.emit({
              zoomInfo: zoomInfo,
              cat: { id: parseInt(dataWs[0].Window_ID, 10) },
              source,
              ctx: null,
            });
          }
        },
      })
    );
  }

  updateWidget() {}

  ngOnDestroy() {
    SubscriptionsUtils.unsubscribe(...this.subscriptions);
  }
}
