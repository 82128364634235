import { NgClass, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DataStoreName, DataStoreStatus } from '@compiere-ws/models/compiere-data-json';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsDataField } from '@iupics-manager/models/iupics-data';
import { TextLimitPipe } from '@iupics-util/pipes/text-limit/text-limit.pipe';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import PrimeOverlayComponent from '../../../overrided/prime-overlay/prime-overlay.component';
import ValuePreferencePanelComponent from '../../value-preference-panel/value-preference-panel.component';

@Component({
  selector: 'iu-input-text-ui',
  templateUrl: './input-text-ui.component.html',
  styleUrls: ['./input-text-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TooltipModule,
    NgIf,
    NgClass,
    FormsModule,
    PrimeOverlayComponent,
    forwardRef(() => ValuePreferencePanelComponent),
    TextLimitPipe,
  ],
})
export default class InputTextUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @Input()
  columnName: string;
  @Input()
  placeHolder: string;
  @ViewChild('input', { static: true })
  inputRef: ElementRef<HTMLInputElement>;
  @ViewChild('opConflict', { static: true })
  opConflict: OverlayPanel;
  dataContainers: AbstractDataContainerCallout;
  @Input() data: IupicsDataField;
  @Input() isKeyUpTriggerable = false;

  @Output() fieldValueChange = new EventEmitter<any>();
  @Output() enterKey = new EventEmitter<Event>();

  ngOnInit() {
    super.ngOnInit();
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
    this.setFieldMandatory();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
    this.checkFocus();
  }

  checkFocus() {
    if (
      this.data?.IsDefaultFocus &&
      !this.isReadOnly &&
      this.editViewParent?.editTabs?.[0]?.dataStored?.status === DataStoreStatus.NEWRECORD &&
      (this.fieldValue === null || this.fieldValue === undefined)
    ) {
      this.inputRef.nativeElement.focus();
    }
  }

  showConflictPanel(ev) {
    ev.target.getBoundingClientRect = function () {
      return { top: this.offsetTop, left: this.offsetLeft };
    };
    this.opConflict.toggle(ev);
  }

  changePassWord() {
    if (this.data && this.data.fieldType === 'password' && this.fieldValue === null) {
      if (this.dataStored && this.dataStored.key) {
        const oldStore = this.store.getStore(this.dataStored.key, DataStoreName.OLD);
        if (oldStore && oldStore.data) {
          if (oldStore.data[this.data.columnName]) {
            this.fieldValue = oldStore.data[this.data.columnName];
          }
        }
      }
    }
  }

  resetPassWord() {
    if (this.data && this.data.fieldType === 'password' && this.fieldValue) {
      if (this.dataStored && this.dataStored.key) {
        const oldStore = this.store.getStore(this.dataStored.key, DataStoreName.OLD);
        if (oldStore && oldStore.data) {
          if (oldStore.data[this.data.columnName] && oldStore.data[this.data.columnName] === this.fieldValue) {
            this.fieldValue = null;
          }
        }
      }
    }
  }

  onUserInput(event: InputEvent) {
    if (
      this.data &&
      this.data.vFormat &&
      event.inputType === 'insertText' &&
      (event.target as HTMLInputElement).value.length > this.data.vFormat.length
    ) {
      (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.slice(
        0,
        this.data.vFormat.length
      );
    }
  }

  onKeyUp(event: KeyboardEvent) {
    if (this.isStandalone && this.isKeyUpTriggerable) {
      this.fieldValueChange.emit((event.target as HTMLInputElement).value);
    }
  }
}
